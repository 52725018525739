<template>
    <div class='container'>
        <HeaderNav />
        <div class="dialogBox" v-if="dialogFlag">
            <div class="dialogInner">
                <div class="dialogT1">恭喜您完善成功</div>
                <div class="dialogT2">请妥善保管您的账户信息</div>
                <div class="dialogT3"></div>
                <div class="dialogT4" @click="goIndex">重新登录</div>
            </div>
        </div>
        <div class="inner">
            <div class="userNickName">{{userFullInfo.account}}</div>
            <div class="userCorporate">{{userFullInfo.corporate}}</div>
            <div class="roleInfo">
                <div class="vipName">{{userFullInfo.membershipRightsName}}</div>
                <div class="endTime">
                    截止日期：{{userFullInfo.membershipEndTime?userFullInfo.membershipEndTime.substring(0,10):''}}</div>
            </div>
            <div class="tabNav">
                <div class="tabs">
                    <div :class="{tab:true,active:activeIndex==0}" @click="changeTab(0)">会员首页</div>
                    <div :class="{tab:true,active:activeIndex==1}" @click="changeTab(1)">收藏</div>
                    <div :class="{tab:true,active:activeIndex==2}" @click="changeTab(2)">资料</div>
                    <div :class="{tab:true,active:activeIndex==3}" @click="changeTab(3)">修改密码</div>
                </div>
            </div>
            <div class="tabContent">
                <div class="vipInfo" v-show="activeIndex==0">
                    <img :src="$RESOURCE_URL+vipBg" alt="">
                </div>
                <div class="myCollect" v-show="activeIndex==1">
                    <div class="tipWhenNo" v-if="collectArr.length==0">暂无收藏</div>
                    <div class="list">
                        <div class="row" v-for="(item,index) in collectArr" :key="item.uuid"
                            @click="goTopic(item.uuid)">
                            <div class="tips">
                                <div class="tip areaTip">{{item.regionName.substring(0,3)}}</div>
                                <div class="tip columnTip">{{item.managementName}}</div>
                                <!-- <div class="tip industryTip">{{item.classificationName}}</div> -->
                            </div>
                            <div class="topicTit">{{item.title}}</div>
                            <div class="topicTime">{{item.createTime}}</div>
                            <div class="collectFlag">
                                <img src="@/assets/img/en_collect.png" alt="" title="取消收藏"
                                    @click.stop="cancelCollect(item,index)">
                                <!-- <img src="@/assets/img/un_collect.png" alt="" v-if="false"> -->
                            </div>
                        </div>
                    </div>
                    <div class="pageNintionBox">
                        <el-pagination background layout="prev, pager, next" :current-page.sync="collectPage"
                            :page-size="collectPageSize" :total="collectTotal" @current-change="loadCollectList"
                            hide-on-single-page>
                        </el-pagination>
                    </div>
                </div>
                <div class="myInfo" v-show="activeIndex==2">
                    <div class="formTit">联系人信息</div>
                    <div class="form-row">
                        <div class="form-key">
                            <div class="form-label">
                                <span class="labelStar" v-if="myInfoForm.corporate.required">*</span>
                                <span class="labelText">{{myInfoForm.corporate.label}}</span>
                            </div>
                        </div>
                        <div class="form-val">
                            <input type="text" v-model="myInfoForm.corporate.val"
                                :placeholder="myInfoForm.corporate.placeholderText" />
                        </div>
                        <div class="form-warn" v-if="!myInfoForm.corporate.canPass">必填</div>
                    </div>
                    <div class="form-row">
                        <div class="form-key">
                            <div class="form-label">
                                <span class="labelStar" v-if="myInfoForm.name.required">*</span>
                                <span class="labelText">{{myInfoForm.name.label}}</span>
                            </div>
                        </div>
                        <div class="form-val">
                            <input type="text" v-model="myInfoForm.name.val"
                                :placeholder="myInfoForm.name.placeholderText" />
                        </div>
                        <div class="form-warn" v-if="!myInfoForm.name.canPass">必填</div>
                    </div>
                    <div class="form-row">
                        <div class="form-key">
                            <div class="form-label">
                                <span class="labelStar" v-if="myInfoForm.landline.required">*</span>
                                <span class="labelText">{{myInfoForm.landline.label}}</span>
                            </div>
                        </div>
                        <div class="form-val">
                            <input type="text" v-model="myInfoForm.landline.val"
                                :placeholder="myInfoForm.landline.placeholderText" />
                        </div>
                        <div class="form-warn" v-if="!myInfoForm.landline.canPass">必填</div>
                    </div>
                    <div class="form-row">
                        <div class="form-key">
                            <div class="form-label">
                                <span class="labelStar" v-if="myInfoForm.email.required">*</span>
                                <span class="labelText">{{myInfoForm.email.label}}</span>
                            </div>
                        </div>
                        <div class="form-val">
                            <input type="text" v-model="myInfoForm.email.val"
                                :placeholder="myInfoForm.email.placeholderText" />
                        </div>
                        <div class="form-warn" v-if="!myInfoForm.email.canPass">必填</div>
                    </div>
                    <div class="form-row" style="margin-botom:50px">
                        <div class="form-key">
                            <div class="form-label">
                                <span class="labelStar" v-if="myInfoForm.r5.required">*</span>
                                <span class="labelText">{{myInfoForm.r5.label}}</span>
                            </div>
                        </div>
                        <div class="form-val" style="display:flex;align-items:center;">
                            <input type="text" v-model="myInfoForm.r5.val" :placeholder="myInfoForm.r5.placeholderText"
                                style="width:208px" />
                            <div class="vaildCode" @click="changeVaildCode">
                                <SIdentify :identifyCode="identifyCode" />
                            </div>

                        </div>
                        <div class=" form-warn" v-if="!myInfoForm.r5.canPass">必填
                        </div>
                    </div>
                    <div class="signBtn" @click="changeInfo">
                        确认修改
                    </div>
                </div>
                <div class="changePass" v-show="activeIndex==3">
                    <div class="form-row">
                        <div class="form-key">
                            <div class="form-label">
                                <span class="labelStar" v-if="changePassForm.oldPwd.required">*</span>
                                <span class="labelText">{{changePassForm.oldPwd.label}}</span>
                            </div>
                        </div>
                        <div class="form-val">
                            <input type="password" v-model="changePassForm.oldPwd.val"
                                :placeholder="changePassForm.oldPwd.placeholderText" AUTOCOMPLETE="off" />
                        </div>
                        <div class="form-warn" v-if="!changePassForm.oldPwd.canPass">必填</div>
                    </div>
                    <div class="form-row">
                        <div class="form-key">
                            <div class="form-label">
                                <span class="labelStar" v-if="changePassForm.newPwd.required">*</span>
                                <span class="labelText">{{changePassForm.newPwd.label}}</span>
                            </div>
                        </div>
                        <div class="form-val">
                            <input type="password" v-model="changePassForm.newPwd.val"
                                :placeholder="changePassForm.newPwd.placeholderText" />
                        </div>
                        <div class="form-warn" v-if="!changePassForm.newPwd.canPass">必填</div>
                    </div>
                    <div class="form-row">
                        <div class="form-key">
                            <div class="form-label">
                                <span class="labelStar" v-if="changePassForm.password_confirm.required">*</span>
                                <span class="labelText">{{changePassForm.password_confirm.label}}</span>
                            </div>
                        </div>
                        <div class="form-val">
                            <input type="password" v-model="changePassForm.password_confirm.val"
                                :placeholder="changePassForm.password_confirm.placeholderText" />
                        </div>
                        <div class="form-warn" v-if="!changePassForm.password_confirm.canPass">必填</div>
                    </div>
                    <div class="signBtn" @click="changePass">
                        确认修改
                    </div>
                </div>

            </div>
        </div>
        <FooterNav />
    </div>
</template>
<style scoped lang="scss">
    .container {
        position: relative;
        padding-top: 40px;

        .dialogBox {
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            position: fixed;
            z-index: 10;
            background: rgba(0, 0, 0, 0.5);

            .dialogInner {
                width: 500px;
                height: 450px;
                padding-top: 69px;
                padding-bottom: 47px;
                box-sizing: border-box;
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url("../assets/img/dialogBg.png");
                background-repeat: no-repeat;
                background-size: cover;

                .dialogT1 {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 500;
                    text-align: center;
                    color: #ffffff;
                    line-height: 28px;
                    margin-bottom: 15px;
                }

                .dialogT2 {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    color: #ffffff;
                    line-height: 20px;
                    margin-bottom: 24px;
                }

                .dialogT3 {
                    background-image: url("../assets/img/dialogCheck.png");
                    background-repeat: no-repeat;
                    width: 140px;
                    height: 140px;
                    margin: 0 auto;
                    margin-bottom: 67px;
                    background-size: cover;
                }

                .dialogT4 {
                    margin: 0 auto;
                    width: 270px;
                    height: 40px;
                    background: #ffffff;
                    border-radius: 20px;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    color: #266ddc;
                    line-height: 40px;
                    cursor: pointer;
                }
            }
        }

        .inner {
            width: 1000px;
            box-sizing: border-box;
            padding: 33px 0px;
            padding-bottom: 66px;
            background-color: #fff;
            border-radius: 20px;
            box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);
            margin: 0 auto;
            margin-bottom: 50px;

            .userNickName {
                font-size: 24px;
                font-weight: 500;
                text-align: center;
                color: #266ddc;
                line-height: 33px;
                margin-bottom: 14px;
            }

            .userCorporate {
                font-size: 16px;
                font-weight: 400;
                text-align: center;
                color: #333333;
                line-height: 22px;
                margin-bottom: 8px;
            }

            .roleInfo {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                color: #666666;
                margin-bottom: 20px;

                .vipName {
                    margin-right: 20px;
                }

                .endTime {}
            }

            .tabNav {
                border-bottom: 1px solid #f5f5f5;
                margin-bottom: 28px;
                font-family: "微软雅黑";

                .tabs {
                    display: flex;
                    align-items: center;
                    justify-content: center;


                    .tab {
                        padding: 4px 3px;
                        border-bottom: 1px solid #fff;
                        font-size: 18px;
                        font-weight: 500;
                        text-align: center;
                        color: #999999;
                        line-height: 25px;
                        margin-right: 54px;
                        cursor: pointer;
                    }

                    .tab:last-child {
                        margin-right: 0;
                    }

                    .active {
                        border-bottom: 1px solid #266ddc;
                        color: #266ddc;
                    }
                }
            }

            .tabContent {
                .vipInfo {
                    width: 700px;
                    height: 487px;
                    margin: 0 auto;

                    img {
                        display: block;
                        width: 700px;
                        height: 487px;
                    }
                }

                .myCollect {
                    padding: 0 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    .tipWhenNo {
                        height: 140px;
                        font-size: 16px;
                        text-align: center;
                        line-height: 140px;
                    }

                    .list {
                        .row {
                            margin-bottom: 16px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            .tips {
                                display: flex;
                                align-items: center;
                                margin-right: 19px;

                                .tip {
                                    width: 100px;
                                    height: 28px;
                                    box-sizing: border-box;
                                    padding: 0 20px;
                                    border-radius: 14px;
                                    font-weight: 400;
                                    text-align: center;
                                    color: #ffffff;
                                    line-height: 28px;
                                    margin-right: 9px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .tip:last-child {
                                    margin-right: 0;
                                }

                                .areaTip {
                                    background: #2984ff;
                                }

                                .columnTip {
                                    background: #00b9d4;
                                }

                                .industryTip {
                                    background: #ff8b00;
                                }
                            }

                            .topicTit {
                                width: 416px;
                                height: 28px;
                                font-size: 16px;
                                font-weight: 400;
                                text-align: left;
                                color: #333333;
                                line-height: 28px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                margin-right: 17px;
                            }

                            .topicTime {
                                font-size: 16px;
                                font-weight: 400;
                                text-align: left;
                                color: #999999;
                                line-height: 22px;
                                margin-right: 16px;
                            }

                            .collectFlag {
                                width: 22px;
                                height: 21px;

                                img {
                                    cursor: pointer;
                                    width: 22px;
                                    height: 21px;
                                }
                            }
                        }

                        .row:hover {
                            .topicTit {
                                color: #266ddc;

                            }
                        }
                    }
                }

                .myInfo {
                    .formTit {
                        font-size: 16px;
                        font-weight: 400;
                        text-align: center;
                        color: #266ddc;
                        line-height: 22px;
                        margin-bottom: 14px;
                    }

                    .form-row {
                        position: relative;
                        width: 410px;
                        margin: 0 auto;
                        display: flex;
                        margin-bottom: 20px;

                        .form-key {
                            width: 64px;
                            height: 32px;
                            font-size: 14px;
                            font-weight: 400;
                            text-align: left;
                            line-height: 32px;
                            margin-right: 16px;

                            .labelStar {
                                font-size: 14px;
                                font-weight: 400;
                                text-align: left;
                                color: #d92e26;
                                line-height: 22px;
                            }

                            .labelText {
                                font-size: 14px;
                                font-weight: 400;
                                text-align: left;
                                line-height: 22px;
                            }
                        }

                        .form-val {
                            input {
                                width: 326px;
                                height: 32px;
                                box-sizing: border-box;
                                padding-left: 12px;
                                font-size: 14px;
                                background: #ffffff;
                                border: 1px solid #dddddd;
                                border-radius: 4px;
                                color: #333;
                            }

                            select {
                                width: 326px;
                                height: 32px;
                                box-sizing: border-box;
                                padding-left: 12px;
                                font-size: 14px;
                                background: #ffffff;
                                border: 1px solid #dddddd;
                                border-radius: 4px;
                                color: #999999;
                                outline: none;
                            }

                            .sendCode {
                                display: inline-block;
                                width: 80px;
                                text-align: center;
                                color: #266ddc;
                                font-size: 14px;
                                cursor: pointer;
                            }

                            .sendCode:hover {
                                text-decoration: underline;
                            }

                            .passDeg {
                                margin: 10px;
                                height: 17px;
                                display: flex;
                                align-items: center;
                                font-size: 14px;

                                .passP {
                                    display: block;
                                    color: #fff;
                                    width: 44px;
                                    height: 12px;
                                    padding: 2px 0;
                                    font-size: 10px;
                                    text-align: center;
                                    line-height: 12px;
                                    margin-right: 2px;
                                }

                                .weakP {
                                    background-color: #DF766F;
                                }

                                .midP {
                                    background-color: #EEB559;
                                }

                                .strongP {
                                    background-color: #cccccc;
                                }
                            }

                            .keywordTip {
                                margin-top: 10px;
                                font-size: 11px;
                                font-weight: 400;
                                text-align: left;
                                color: #666666;
                                line-height: 22px;
                            }

                            .signTip {
                                margin-top: 14px;
                                font-size: 14px;
                                color: #666666;

                                span {
                                    color: #266ddc;
                                    cursor: pointer;
                                }

                                span:hover {
                                    text-decoration: underline;
                                }
                            }

                            .vaildCode {
                                cursor: pointer;
                            }
                        }

                        .form-warn {
                            position: absolute;
                            right: -35px;
                            height: 32px;
                            line-height: 32px;
                            color: #d92e26;
                            font-size: 14px;
                            margin-left: 2px;
                        }
                    }

                    .signBtn {

                        width: 270px;
                        height: 40px;
                        margin: 0 auto;
                        text-align: center;
                        line-height: 40px;
                        color: white;
                        background: linear-gradient(90deg, #4097ec, #3d81ea);
                        border-radius: 20px;
                        font-size: 18px;
                        margin-top: 40px;
                        margin-bottom: 14px;
                        cursor: pointer;
                    }
                }

                .changePass {
                    .form-row {
                        position: relative;
                        width: 410px;
                        margin: 0 auto;
                        display: flex;
                        margin-bottom: 20px;

                        .form-key {
                            width: 64px;
                            height: 32px;
                            font-size: 14px;
                            font-weight: 400;
                            text-align: left;
                            line-height: 32px;
                            margin-right: 16px;

                            .labelStar {
                                font-size: 14px;
                                font-weight: 400;
                                text-align: left;
                                color: #d92e26;
                                line-height: 22px;
                            }

                            .labelText {
                                font-size: 14px;
                                font-weight: 400;
                                text-align: left;
                                line-height: 22px;
                            }
                        }

                        .form-val {
                            input {
                                width: 326px;
                                height: 32px;
                                box-sizing: border-box;
                                padding-left: 12px;
                                font-size: 14px;
                                background: #ffffff;
                                border: 1px solid #dddddd;
                                border-radius: 4px;
                                color: #999999;
                            }

                            select {
                                width: 326px;
                                height: 32px;
                                box-sizing: border-box;
                                padding-left: 12px;
                                font-size: 14px;
                                background: #ffffff;
                                border: 1px solid #dddddd;
                                border-radius: 4px;
                                color: #999999;
                                outline: none;
                            }

                            .sendCode {
                                display: inline-block;
                                width: 80px;
                                text-align: center;
                                color: #266ddc;
                                font-size: 14px;
                                cursor: pointer;
                            }

                            .sendCode:hover {
                                text-decoration: underline;
                            }

                            .passDeg {
                                margin: 10px;
                                height: 17px;
                                display: flex;
                                align-items: center;
                                font-size: 14px;

                                .passP {
                                    display: block;
                                    color: #fff;
                                    width: 44px;
                                    height: 12px;
                                    padding: 2px 0;
                                    font-size: 10px;
                                    text-align: center;
                                    line-height: 12px;
                                    margin-right: 2px;
                                }

                                .weakP {
                                    background-color: #DF766F;
                                }

                                .midP {
                                    background-color: #EEB559;
                                }

                                .strongP {
                                    background-color: #cccccc;
                                }
                            }

                            .keywordTip {
                                margin-top: 10px;
                                font-size: 11px;
                                font-weight: 400;
                                text-align: left;
                                color: #666666;
                                line-height: 22px;
                            }

                            .signTip {
                                margin-top: 14px;
                                font-size: 14px;
                                color: #666666;

                                span {
                                    color: #266ddc;
                                    cursor: pointer;
                                }

                                span:hover {
                                    text-decoration: underline;
                                }
                            }

                            .vaildCode {
                                cursor: pointer;
                            }
                        }

                        .form-warn {
                            position: absolute;
                            right: -35px;
                            height: 32px;
                            line-height: 32px;
                            color: #d92e26;
                            font-size: 14px;
                            margin-left: 2px;
                        }
                    }

                    .signBtn {

                        width: 270px;
                        height: 40px;
                        margin: 0 auto;
                        text-align: center;
                        line-height: 40px;
                        color: white;
                        background: linear-gradient(90deg, #4097ec, #3d81ea);
                        border-radius: 20px;
                        font-size: 18px;
                        margin-top: 40px;
                        margin-bottom: 14px;
                        cursor: pointer;
                    }
                }
            }

        }
    }
</style>
<script>
    import HeaderNav from "@/components/HeaderNav"
    import FooterNav from "@/components/FooterNav"
    import areas from "@/utils/area"
    import SIdentify from "@/components/SIdentify"
    import {
        getBasicInfo
    } from "@/request/webinfo"
    import {
        register,
        updatePass,
        // resetPass,
        setPass,
        loginOut,
        updateInfo,
        sendCode,
        getDetail
    } from "@/request/user"
    import {
        collectList,
        collectTopic
    } from "@/request/topic"
    export default {
        name: 'Sign',

        props: {

        },
        data() {
            return {
                activeIndex: 0,
                dialogFlag: false,
                vipBg: "",
                collectArr: [],
                collectPage: 1,
                collectTotal: 0,
                collectPageSize: 9,
                //修改资料表单
                myInfoForm: {
                    corporate: {
                        label: "公司名称",
                        type: "input",
                        required: true,
                        maxlength: 20,
                        placeholderText: "请输入公司名称",
                        canPass: true,
                        val: ""
                    },
                    name: {
                        label: "联系人",
                        type: "input",
                        required: true,
                        maxlength: 11,
                        placeholderText: "请输入联系人",
                        canPass: true,
                        val: ""
                    },
                    landline: {
                        label: "固定电话",
                        type: "input",
                        required: false,
                        maxlength: 11,
                        placeholderText: "请输入固定电话",
                        canPass: true,
                        val: ""
                    },
                    email: {
                        label: "邮箱",
                        type: "input",
                        required: true,
                        maxlength: 11,
                        placeholderText: "请输入邮箱",
                        canPass: true,
                        val: ""
                    },
                    r5: {
                        label: "验证码",
                        type: "input",
                        required: true,
                        maxlength: 11,
                        placeholderText: "请输入验证码",
                        canPass: true,
                        val: ""
                    },
                },
                identifyCode: '',
                //修改密码表单
                changePassForm: {
                    oldPwd: {
                        label: "原密码",
                        type: "input",
                        required: true,
                        maxlength: 20,
                        placeholderText: "请输入原密码",
                        canPass: true,
                        val: ""
                    },
                    newPwd: {
                        label: "新密码",
                        type: "input",
                        required: true,
                        maxlength: 11,
                        placeholderText: "请输入新密码",
                        canPass: true,
                        val: ""
                    },
                    password_confirm: {
                        label: "确认密码",
                        type: "input",
                        required: true,
                        maxlength: 11,
                        placeholderText: "请输入确认密码",
                        canPass: true,
                        val: ""
                    },
                },
                userFullInfo: {}
            };
        },
        computed: {
            userInfo() {
                let userInfo = this.$store.state.userInfo
                if (userInfo) {
                    return this.$store.state.userInfo
                }
            }
        },
        created() {
            if (!this.$store.state.userInfo) {
                this.goIndex()
            }
        },
        mounted() {

            if (this.$route.query.tabIndex) {
                this.activeIndex = this.$route.query.tabIndex
            }
            this.changeVaildCode()
            getDetail({
                id: this.$store.state.userInfo.id
            }).then(res => {
                this.userFullInfo = res.data;
                let myInfoForm = this.myInfoForm;
                for (let key in myInfoForm) {
                    myInfoForm[key].val = this.userFullInfo[key];
                }
                this.myInfoForm = myInfoForm;
            })
            getBasicInfo({}).then(res => {
                this.vipBg = res.data[0].personalPic
            })
            this.loadCollectList();
        },
        watch: {

        },
        methods: {
            //收藏
            loadCollectList() {
                collectList({
                    pageSize: this.collectPageSize,
                    currentPage: this.collectPage
                }).then(res => {
                    this.collectTotal = parseInt(res.data.total);
                    this.collectArr = res.data.records;
                    this.collectPage = parseInt(res.data.current);
                })
            },
            cancelCollect(item, index) {
                collectTopic(item).then(res => {
                    this.$message.success("取消收藏成功")
                    this.collectArr.splice(index, 1)
                    if (this.collectArr.length == 0) {
                        this.collectPage = this.collectPage > 1 ? this.collectPage - 1 : 1
                    }
                    this.loadCollectList()
                })
            },
            goTopic(uuid) {
                this.$router.push({
                    name: "Topic",
                    query: {
                        id: uuid
                    }
                })
            },
            changeTab(i) {
                this.activeIndex = i
            },
            changeVaildCode() {
                this.identifyCode = this.randomNum(1000, 9999).toString()
            },
            randomNum(min, max) {
                return Math.floor(Math.random() * (max - min) + min)
            },
            //退出登录
            loginOut() {
                this.dialogFlag = true;
                loginOut({}).then(res => {
                    this.$store.commit("removeUserInfo");

                })
            },
            //返回首页
            goIndex() {
                this.dialogFlag = false;
                this.$router.push({
                    name: "Index"
                })
            },
            //表单处理
            form_fix(form) {
                let result = {};
                for (let key in form) {
                    result[key] = form[key].val;
                }
                return result;
            },
            //表单校验
            form_check(form) {
                let canPass = true;
                for (let key in form) {
                    if (form[key].required) {
                        if (!form[key].val) {
                            form[key].canPass = false;
                            canPass = false;
                        } else {
                            form[key].canPass = true;
                        }
                    }
                }
                if (canPass) {
                    return this.form_fix(form);
                }
                return canPass
            },
            //更新密码
            changePass() {
                let _data = this.form_check(this.changePassForm)
                if (_data) {
                    if (_data.newPwd.length < 6) {
                        this.$message.error("设定密码不可少于6位");
                        return false
                    } else {
                        updatePass(_data).then(res => {
                            if (res.code != 200) {} else {
                                this.dialogFlag = true;
                                this.loginOut();
                            }
                        })
                    }
                }
            },
            //更新信息
            changeInfo() {
                //检验必填
                let form = this.form_check(this.myInfoForm);
                if (form) {
                    //验证码校验
                    if (form.r5 === this.identifyCode) {
                        delete form.r5
                        updateInfo(form).then(res => {
                            this.$message.success(res.data)
                        })
                    } else {
                        this.$set(this.myInfoForm, "r5", {
                            label: "验证码",
                            type: "input",
                            required: true,
                            maxlength: 11,
                            placeholderText: "请输入验证码",
                            canPass: true,
                            val: ""
                        })
                        this.changeVaildCode()
                        this.$message.error("验证码不正确");
                    }
                }
            },

        },
        components: {
            HeaderNav,
            FooterNav,
            SIdentify
        },
    };
</script>